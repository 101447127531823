import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./styles.css";
import "./my-custom-block";
import "./upload-image-block";
import axios from "axios";

registerCoreBlocks();
const App = () => {
  const namaValue = useFieldAnswer("nama");
  const jenis_kelaminValue = useFieldAnswer("jenis_kelamin");
  const nomor_waValue = useFieldAnswer("nomor_wa");
  const re_nomor_waValue = useFieldAnswer("re_nomor_wa");
  const emailValue = useFieldAnswer("email");
  const ttlValue = useFieldAnswer("ttl");
  const alamatValue = useFieldAnswer("alamat");
  const statusValue = useFieldAnswer("status");
  const pekerjaanValue = useFieldAnswer("pekerjaan");
  const pemberitahuan_1Value = useFieldAnswer("pemberitahuan_1");
  const pemberitahuan_2Value = useFieldAnswer("pemberitahuan_2");
  const is_alumniValue = useFieldAnswer("is_alumni");
  const biayaValue = useFieldAnswer("biaya");
  const rekeningValue = useFieldAnswer("rekening");
  const fileValue = useFieldAnswer("file");
  const pemberitahuan_3Value = useFieldAnswer("pemberitahuan_3");
  const pemberitahuan_4Value = useFieldAnswer("pemberitahuan_4");
  const buktiValue = useFieldAnswer("bukti");
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Form
        formId="1"
        formObj={{
          blocks: [
            {
              name: "welcome-screen",
              id: "welcome",
              attributes: {
                buttonText: "Daftar Sekarang",
                label:
                  "KELAS MAKE UP FLAWLESS & NATURAL ONLINE \n\n Belajar Mempercantik Diri Ala Bidadari Dunia Agar Makin Disayang Suami",
                description:
                  "Insya Allah diselenggarakan secara Online pada Ahad, 5 - 6 Agustus 2023 \n\n Pukul 08.00 WIB - 09.30 WIB",
                attachment: {
                  type: "image",
                  url: "images/flyer.jpg",
                },
                attachmentMaxWidth: "50%",
              },
            },
            {
              name: "short-text",
              id: "nama",
              attributes: {
                required: true,
                label: "Nama Lengkap Sesuai KTP",
              },
            },
            {
              name: "multiple-choice",
              id: "jenis_kelamin",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label: "Jenis Kelamin",
                choices: [
                  {
                    label: "Pria",
                    value: "Pria",
                  },
                  {
                    label: "Wanita",
                    value: "Wanita",
                  },
                ],
              },
            },
            {
              name: "short-text",
              id: "nomor_wa",
              attributes: {
                required: true,
                label:
                  "Nomor Whatsapp (Format nomor WhatsApp WAJIB seperti ini : 085273992774)",
              },
            },
            {
              name: "short-text",
              id: "re_nomor_wa",
              attributes: {
                required: true,
                label:
                  "Ulangi Ketik Nomor WhatsApp (Format nomor WhatsApp WAJIB seperti ini : 085273992774 , jika berbeda maka data anda akan ERROR)",
              },
            },
            {
              name: "email",
              id: "email",
              attributes: {
                required: true,
                label: "Alamat Email",
              },
            },
            {
              name: "short-text",
              id: "ttl",
              attributes: {
                required: true,
                label:
                  "Tempat & Tanggal Lahir (Contoh : Surabaya, 1 Agustus 1975)",
              },
            },
            {
              name: "short-text",
              id: "alamat",
              attributes: {
                required: true,
                label:
                  "Alamat Domisili Lengkap (akan digunakan untuk dikirimi hadiah dari panitia jika anda beruntung)",
              },
            },
            {
              name: "multiple-choice",
              id: "status",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label: "Pilih salah satu status dibawah ini :",
                choices: [
                  {
                    label: "Menikah",
                    value: "Menikah",
                  },
                  {
                    label:
                      "Belum pernah menikah, tapi akan menikah dalam waktu dekat",
                    value:
                      "Belum pernah menikah, tapi akan menikah dalam waktu dekat",
                  },
                  {
                    label: "Duda / Janda",
                    value: "Duda / Janda",
                  },
                ],
              },
            },
            {
              name: "short-text",
              id: "pekerjaan",
              attributes: {
                required: true,
                label: "Pekerjaan Saat ini (Contoh : Pedagang Buah)",
              },
            },
            {
              name: "multiple-choice",
              id: "pemberitahuan_1",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label:
                  "Event BrainHacking ini diadakan secara Online melalui platform www.TemuOnline.com, pada tanggal 22 Januari 2023, Ahad. Pukul 15.30 - 17.00 WIB (Part.1) dan lanjut 20.00 - 22.30 WIB (Part.2)",
                choices: [
                  {
                    label: "Baik, saya mengerti",
                    value: "Baik, saya mengerti",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "pemberitahuan_2",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label:
                  "Workshop BrainHacking ini tidak direkam, namun para peserta akan mendapat PPT yang disusun oleh pemateri. Dilarang keras menyebarluaskan PPT tersebut tanpa seizin penulis.",
                choices: [
                  {
                    label: "Baik, saya mengerti",
                    value: "Baik, saya mengerti",
                  },
                ],
              },
            },
            {
              name: "upload-image-block",
              id: "bukti",
              attributes: {
                label:
                  "Mohon kirimkan bukti screenshot anda telah menyimpan nomor 0899-0075-396 di kontak HP anda, dengan nama : Admin BrainHacking.ID",
                description: "File JPG / PNG",
                required: true,
              },
            },
            {
              name: "multiple-choice",
              id: "is_alumni",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label: "Apakah anda alumni BrainHacking 11 Desember ?",
                choices: [
                  {
                    label: "Ya",
                    value: "Ya",
                  },
                  {
                    label: "Tidak",
                    value: "Tidak",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "biaya",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label:
                  is_alumniValue && is_alumniValue[0] === "Tidak"
                    ? "Silahkan pilih salah satu biaya registrasi yang sesuai dengan kemampuan finansial anda, yang mana akan kami manfaatkan untuk mendanai pengembangan Platform TemuOnline.com agar bisa tetap GRATIS selamanya, dan membiayai semua sekolah GRATIS yang sedang dirintis oleh Yayasan Sahabat Tauhid al-Atsariy"
                    : "Apabila Anda Alumni BrainHacking 11 Desember kemarin, silahkan upload bukti bayar yang telah Anda bayarkan",
                choices:
                  is_alumniValue && is_alumniValue[0] === "Tidak"
                    ? [
                        {
                          label: "Rp 75.000",
                          value: "75000",
                        },  
                        {
                          label: "Rp 100.000",
                          value: "100000",
                        },
                        {
                          label: "Rp 150.000",
                          value: "150000",
                        },
                        {
                          label: "Rp 175.000",
                          value: "175000",
                        },
                      ]
                    : [
                        {
                          label: "Baik, saya mengrti",
                          value: "Baik, saya mengrti",
                        },
                      ],
              },
            },
            {
              name: "multiple-choice",
              id: "rekening",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label:
                  "Silahkan pilih salah satu nomor rekening tujuan untuk menyelesaikan biaya registrasi",
                choices: [
                  {
                    label:
                      "Bank Syariah Indonesia - (Kode Bank 451) 72-31-75-88-06 / A.N Yayasan Sahabat Tauhid al Atsariy",
                    value:
                      "Bank Syariah Indonesia - (Kode Bank 451) 72-31-75-88-06 / A.N Yayasan Sahabat Tauhid al Atsariy",
                  },
                ],
              },
            },
            {
              name: "upload-image-block",
              id: "file",
              attributes: {
                label:
                  "Mohon upload bukti Transfer sesuai Biaya Registrasi yang anda pilih.",
                description: "File JPG / PNG",
                required: true,
              },
            },
            {
              name: "multiple-choice",
              id: "pemberitahuan_3",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label:
                  "Peserta yang telah melunasi biaya registrasi akan dihubungi panitia 1x24 Jam, jika lebih dari itu belum dihubungi via pesan Whatsapp, mohon hubungi WA Admin BrainHacking.ID (0899-0075-396)",
                choices: [
                  {
                    label: "Baik, saya mengerti",
                    value: "Baik, saya mengerti",
                  },
                ],
              },
            },
            {
              name: "multiple-choice",
              id: "pemberitahuan_4",
              attributes: {
                required: true,
                multiple: false,
                verticalAlign: false,
                label:
                  "Bagi peserta yang telah melunasi biaya registrasi, tapi Qadarallah di hari-H berhalangan hadir, maka boleh ikut di workshop berikutnya, harus registrasi ulang, namun cukup upload bukti pembayaran sebelumnya.",
                choices: [
                  {
                    label: "Baik, saya mengerti",
                    value: "Baik, saya mengerti",
                  },
                ],
              },
            },
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: false,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          theme: {
            font: "Roboto",
            backgroundColor: "#ffde59",
            buttonsBgColor: "#000000",
            logo: {
              src: "",
            },
            questionsColor: "#000",
            answersColor: "#1d190a",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#f00",
            progressBarFillColor: "#000",
            progressBarBgColor: "#ccc",
          },
          messages: {
            "label.hintText.enter": "Tekan <strong>Enter ↵</strong>",
            "block.defaultThankYouScreen.label": `Jazaakumullahu khayran telah melakukan registrasi. Sesaat lagi akan ada Robot WhatsApp yang akan mengirimi anda pesan, memerintahkan untuk menyimpan nomor 0813-4495-8827 di kontak HP dengan nama 'Admin BrainHacking.ID' \n\n
            Jika anda tidak mendapat pesan dari Robot  WA paling lambat 2 menit, maka anda telah gagal registrasi, WAJIB menghubungi WA Admin BrainHacking di 0813-4495-8827`,
          },
        }}
        onSubmit={async (data, { completeForm, setIsSubmitting }) => {
          const formData = new FormData();
          formData.append("nama", namaValue);
          formData.append("jenis_kelamin", jenis_kelaminValue[0]);
          formData.append("nomor_wa", nomor_waValue);
          formData.append("re_nomor_wa", re_nomor_waValue);
          formData.append("email", emailValue);
          formData.append("ttl", ttlValue);
          formData.append("alamat", alamatValue);
          formData.append("status", statusValue[0]);
          formData.append("pekerjaan", pekerjaanValue);
          formData.append("pemberitahuan_1", pemberitahuan_1Value[0]);
          formData.append("pemberitahuan_2", pemberitahuan_2Value[0]);
          formData.append("is_alumni", is_alumniValue[0]);
          formData.append("biaya", biayaValue[0]);
          formData.append("rekening", rekeningValue[0]);
          formData.append("file", fileValue.file);
          formData.append("pemberitahuan_3", pemberitahuan_3Value[0]);
          formData.append("pemberitahuan_4", pemberitahuan_4Value[0]);
          formData.append("bukti", buktiValue.file);
          const payload = {
            nama: namaValue,
            jenis_kelamin: jenis_kelaminValue[0],
            nomor_wa: nomor_waValue,
            re_nomor_wa: re_nomor_waValue,
            email: emailValue,
            ttl: ttlValue,
            alamat: alamatValue,
            status: statusValue[0],
            pekerjaan: pekerjaanValue,
            pemberitahuan_1: pemberitahuan_1Value[0],
            pemberitahuan_2: pemberitahuan_2Value[0],
            is_alumni: is_alumniValue[0],
            biaya: biayaValue[0],
            rekening: rekeningValue[0],
            file: fileValue.file,
            pemberitahuan_3: pemberitahuan_3Value[0],
            pemberitahuan_4: pemberitahuan_4Value[0],
            bukti: buktiValue.file,
          };
          console.log("payload", payload);
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_URL.concat("/api/registrations"),
              formData,
              {
                headers: {
                  "Content-type": "multipart/form-data",
                },
              }
            );
            if (response.data.success) {
              const message_payload = {
                phone: "62" + payload.nomor_wa.substring(1),
                messageType: "text",
                body: `Dengan Hormat, ${payload.nama}. Anda telah tergabung dalam Kelas Makeup Flawless & Natural Online - "Belajar Mempercantik Diri Ala Bidadari Dunia Agar Makin Disayang Suami", Insya Allah diadakan 5-6 Agustus 2023. Ini merupakan pesan otomatis dari Robot WhatsApp. JANGAN DIBALAS & JANGAN DISIMPAN!\n\n
                Anda WAJIB menyimpan nomor 0813-4495-8827 di  Kontak anda dengan nama : "Admin BrainHacking.ID" . Jika anda tidak menyimpan nomor tersebut di kontak anda, maka WhatsApp anda  tidak akan bisa mendapatkan Broadcast berkenaan kelas makeup.\n\n
                Mohon segera simpan nomor tersebut setelah membaca pesan ini. Panitia TIDAK BOLEH disalahkan atas kelalaian anda karena tidak segera menyimpan nomor 0813-4495-8827 di kontak HP anda.\n\n
                Sebagai antisipasi jika sewaktu-waktu server WhatsApp ERROR, tolong join juga Grup WA berikut ini : https://chat.whatsapp.com/GXNQGmoeMCk1t01vmGpLwG , agar mendapatkan info terkini `,
              };
              const message = await axios.post(
                `${process.env.REACT_APP_WA_API_URL}/api/v1/message/send_whatsapp`,
                message_payload,
                {
                  headers: {
                    "API-Key": process.env.REACT_APP_WA_API_KEY,
                  },
                }
              );
              setTimeout(() => {
                setIsSubmitting(false);
                completeForm();
              }, 500);
            } else {
              setTimeout(() => {
                setIsSubmitting(false);
                alert("Pendaftaran Gagal!");
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              setIsSubmitting(false);
              alert("Pendaftaran Gagal!");
            }, 500);
          }
        }}
      />
    </div>
  );
};

export default App;
