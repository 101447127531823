/**
 * QuillForms Depndencies
 */
import { useTheme, useMessages } from "@quillforms/renderer-core";

/**
 * React Dependencies
 */
import { useEffect, useState } from "react";

/**
 * External Dependencies
 */
import tinyColor from "tinycolor2";
import { css } from "emotion";
import classnames from "classnames";
import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";

import ImageUploading from "react-images-uploading";

let timer;
const UploadImageBlockDisplay = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next,
  } = props;

  const { required, start, end } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  const answersColor = tinyColor(theme.answersColor);

  const checkfieldValidation = (value) => {
    if (required === true && (!value || value === "")) {
      setIsValid(false);
      setValidationErr(messages["label.errorAlert.required"]);
    } else {
      setIsValid(true);
      setValidationErr(null);
    }
  };

  const items = [];
  for (let i = start; i <= end; i++) {
    items.push(i);
  }

  useEffect(() => {
    if (!isActive) {
      clearTimeout(timer);
    }
  }, [isActive]);

  useEffect(() => {
    checkfieldValidation(val);
  }, [val]);

  const [images, setImages] = useState([]);
  const maxNumber = 4;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    if (imageList.length === 0) {
      clearTimeout(timer);
      setIsAnswered(false);
      setVal("");
    } else {
      setVal(imageList[0].data_url);
      timer = setTimeout(() => {
        setIsAnswered(true);
        next();
      }, 500);
    }
  };

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }
  function getImageFileObject(imageFile) {
    console.log("idd", id);
    console.log("value", val);
    console.log("getImageFileObject", { imageFile });
    if (!isEmpty(imageFile)) {
      console.log("ganti val", imageFile);
      setVal(imageFile);
      timer = setTimeout(() => {
        setIsAnswered(true);
        next();
      }, 500);
    }
  }
  function runAfterImageDelete(file) {
    console.log("runAfterImageDelete", { file });
    clearTimeout(timer);
    setIsAnswered(false);
    setVal("");
  }

  return (
    <div id={id}>
      <ImageUploader
        onFileAdded={(img) => getImageFileObject(img)}
        onFileRemoved={(img) => runAfterImageDelete(img)}
      />
      <br />
      {val && <img src={val?.dataUrl} alt="preview" width="50%" />}
    </div>
  );
};
export default UploadImageBlockDisplay;
